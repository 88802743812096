// Navbar dropped in from FullStackRocket... and updated jss-to-styled. muiV5
// updated to suit logo, contact and social links.

import React from 'react';
import { Link } from 'gatsby';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import PhoneAndroid from '@mui/icons-material/PhoneAndroid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import useSiteMetadata from '../../src/components/hooks/SiteMetadata';

import MUILink from '@mui/material/Link';

// import NuDialog from './NuDialog';
// import DSPTimetable from './DSP-Timetable';

// import logo from '../img/Logo.png';

const drawerWidth = 240;

const DrawerNav = styled('nav')(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		width: drawerWidth,
		flexShrink: 0,
	},
}));
const AppBarStyled = styled(AppBar)(({ theme }) => ({
	color: theme.palette.primary.dark,
	// backgroundColor: 'white',
	[theme.breakpoints.up('sm')]: {
		width: `100%`,
	},
}));
const MainToolbar = styled(Toolbar)(({ theme }) => ({
	justifyContent: 'space-evenly',
	height: '90px',
	'.MuiButtonBase-root': {
		borderRadius: 0,
		':hover': {
			transition: 'all 0.5s ease',
			// backgroundColor: 'rgba(255,255,255,0.3)',
			backgroundColor: 'rgba(255,255,255,0.05)',
			// color: theme.palette.primary.main,
		},
	},
	'& a': {
		color: '#fff',
		'&:hover': {
			textDecoration: 'none',
		},
	},
	[theme.breakpoints.down('sm')]: {
		justifyContent: 'space-between',
	},
}));

const items = [
	{ path: '/', label: 'Home' },
	{
		path: '',
		label: 'About',
		subs: [
			// { path: '/about-DF', label: 'The Dance Fusion Way' },
			{ path: '/about-DF', label: 'Our Story' },
			{ path: '/about-DF#team', label: 'Our Team' },
		],
	},
	{
		path: '',
		label: 'Important Info',
		subs: [
			{ path: '/code-of-conduct', label: 'Code of Conduct' },
			{
				path: '/enrolment-tcs',
				label: 'Enrolment Terms & Conditions',
			},
			{ path: '/fees-payment', label: '2022 Membership Fees' },
			// { path: '/info-pages/important-dates/', label: 'Important Dates' },
			// { path: '/info-pages/what-to-wear/', label: 'What to Wear' },
			{ path: '/our-classes', label: 'All Classes' },
		],
	},
	// { path: '/blog', label: 'Blog' },

	{ path: 'javascript:void(Tawk_API.toggle())', label: 'Contact' },
	{
		path: 'https://dancestudio-pro.com/online/index.php?account_id=16721',
		label: 'Parent Portal Login',
	},
];

const leftLinks = [
	{
		id: '1',
		path: '',
		label: `Phone: (08) 9375 6100`,
		SocialIcon: PhoneAndroid,
		toolTip: 'Transit Protective Case Specialist',
	},
	// {
	// 	id: '2',
	// 	path: 'https://www.facebook.com/dancefusioncommunity/',
	// 	label: '',
	// 	SocialIcon: FacebookIcon,
	// 	toolTip: 'Facebook',
	// },
	// {
	// 	id: '3',
	// 	path: 'https://www.instagram.com/dance_fusion_official/',
	// 	label: '',
	// 	SocialIcon: InstagramIcon,
	// 	toolTip: 'Insta',
	// },
];
const rightLinks = [
	// {
	// 	id: '1',
	// 	path: '',
	// 	label: 'tel: +61 (08) 9279 5666',
	// 	SocialIcon: PhoneAndroid,
	// 	toolTip: 'Transit Protective Case Specialist',
	// },
	{
		id: '2',
		path: '{facebook}',
		label: '',
		SocialIcon: FacebookIcon,
		toolTip: 'Facebook',
	},
	{
		id: '3',
		path: '{insta}',
		label: '',
		SocialIcon: InstagramIcon,
		toolTip: 'Insta',
	},
];

const navItems = [
	{ path: '/', label: 'Home' },
	{ path: '/blog', label: 'Articles' },
	{
		path: '/#services',
		label: 'Services',
		services: '',
	},
	{
		path: '/team',
		label: 'Team',
		team: '',
	},
	{ path: '/clinic-details', label: 'Clinic Details' },
	{ path: 'javascript:void(Tawk_API.toggle())', label: 'Contact Us' },
];

export default function NavBarLogoSocial(props) {
	const { container } = props;
	const { 
		title, 
		mainLogo, 
		description, 
		phone1, 
		facebook, 
		insta 
	} = useSiteMetadata();
	// const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	// TS NOTES: check muiv5 docs for any updates on the drawer component
	const drawer = (
		<Box
			sx={{
				'.MuiLink-root': {
					color: 'inherit',
					textDecoration: 'none',
				},
			}}
		>
			<Stack spacing={2} justifyContent='center'>
					<Tooltip
						title='Phone'
						placement={
							typeof window !== 'undefined' && window.innerWidth > 959 ? 'top' : 'left'
						}
					>
						<ListItem
							button
							sx={{ display: { xs: 'flex', md: '' }, justifyContent: 'center' }}
						>
							{/* <Typography variant='h4'>{link.label}</Typography> */}
							<MUILink component='button'  sx={{ display: 'flex' }}>
								{/* <PhoneAndroid /> */}
								<Typography variant='h3'>{phone1}</Typography>
							</MUILink>
						</ListItem>
					</Tooltip>
			</Stack>
			<Stack direction='row' spacing={2} justifyContent='center'>
					<Tooltip
						title='Facebook'
						placement={
							typeof window !== 'undefined' && window.innerWidth > 959 ? 'top' : 'left'
						}
					>
						<ListItem
							button
							sx={{
								display: { xs: 'flex', md: '' },
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{/* <Typography variant='h4'>{link.label}</Typography> */}
							<MUILink component={Link} to={facebook} sx={{ display: 'flex' }}>
							<FacebookIcon/>
							
							</MUILink>
						</ListItem>
					</Tooltip>
					<Tooltip
						title='Instagram'
						placement={
							typeof window !== 'undefined' && window.innerWidth > 959 ? 'top' : 'left'
						}
					>
						<ListItem
							button
							sx={{
								display: { xs: 'flex', md: '' },
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{/* <Typography variant='h4'>{link.label}</Typography> */}
							<MUILink component={Link} to={insta} sx={{ display: 'flex' }}>
							<InstagramIcon />
							
							</MUILink>
						</ListItem>
					</Tooltip>
			</Stack>
			<Box sx={(theme) => theme.mixins.toolbar} />
			<Stack
				spacing={2}
				justifyContent='center'
				className='menuItems'
				sx={{
					'.MuiLink-root': {
						display: 'flex',
						alignContent: 'center',
						justifyContent: 'space-between',
						width: '100%',
					},
					'.dialogButton': {
						color: 'rgba(33, 33, 33, 0.9)',
						textAlign: 'left',
						justifyContent: 'flex-start',
						padding: '8px 16px',
						'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
					},
				}}
			>
				{navItems.map((item) => (
					<ListItem button key={item.path} sx={{ flexDirection: 'column' }}>
						<MUILink component={Link} to={item.path}>
							<ListItemText primary={item.label} onClick={handleClick} />
							{item.sub && (
								open ? (
								<ExpandLess sx={{ m: 'auto' }} />
							) : (
								<ExpandMore sx={{ m: 'auto' }} />
							))}
						</MUILink>
						<>
							{item.subs ? (
								<Collapse
									in={open}
									timeout='auto'
									unmountOnExit
									sx={{
										width: 'calc(100% + 32px)',
										backgroundColor: '#ffffff21',

										position: 'relative',
									}}
								>
									<List
										key={`${item.subs.path}-subs`}
										id={`basic-${item.label}`}
										// anchorEl={anchorEl && anchorEl[item.label]}
										// open={anchorEl && Boolean(anchorEl[item.label])}
										onClose={handleClick}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
										sx={{ '.MuiPaper-root': { backgroundColor: '#fff' } }}
									>
										{item.subs?.map((sub, i) => (
											<ListItem
												key={`${sub.path}-sub-${i}`}
												// onClick={handleClose}
												component={Link}
												to={sub.path}
											>
												{sub.label}
											</ListItem>
										))}
									</List>
								</Collapse>
							) : null}
						</>
					</ListItem>
				))}
			</Stack>
		</Box>
	);

	return (
		<>
			<AppBarStyled position='fixed'>
				<MainToolbar>
					<MUILink
						component={Link}
						sx={{ margin: { xs: 'auto', md: '0 116px 0 0' } }}
						to='/'
					>
						<img // TS NOTES: can we use gatsby image here?
							src={mainLogo}
							alt={title}
							style={{ height: '84px', padding: '10px 0 4px' }}
						/>
					</MUILink>
					<List sx={{ display: { xs: 'none', md: 'flex' }, height: '100%', py: 0 }}>
						<Tooltip
						title='Phone'
						placement={
							typeof window !== 'undefined' && window.innerWidth > 959 ? 'top' : 'left'
						}
					>
						<ListItem
							button
							sx={{ display: { xs: 'flex', md: '' }, justifyContent: 'center' }}
						>
							{/* <Typography variant='h4'>{link.label}</Typography> */}
							<MUILink component='button'  sx={{ display: 'flex', color: '#fff' }}>
								<PhoneAndroid sx={{margin: '4px 14px 4px 4px'}} />
								<Typography variant='h3' sx={{color: '#fff'}}>{phone1}</Typography>
							</MUILink>
						</ListItem>
					</Tooltip>
					</List>

					<List sx={{ display: { xs: 'none', md: 'flex' }, height: '100%', py: 0 }}>
						<Tooltip
						title='Facebook'
						placement={
							typeof window !== 'undefined' && window.innerWidth > 959 ? 'top' : 'left'
						}
					>
						<ListItem
							button
							sx={{
								display: { xs: 'flex', md: '' },
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{/* <Typography variant='h4'>{link.label}</Typography> */}
							<MUILink component={Link} to={facebook} sx={{ display: 'flex' }}>
							<FacebookIcon/>
							
							</MUILink>
						</ListItem>
					</Tooltip>
					<Tooltip
						title='Instagram'
						placement={
							typeof window !== 'undefined' && window.innerWidth > 959 ? 'top' : 'left'
						}
					>
						<ListItem
							button
							sx={{
								display: { xs: 'flex', md: '' },
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{/* <Typography variant='h4'>{link.label}</Typography> */}
							<MUILink component={Link} to={insta} sx={{ display: 'flex' }}>
							<InstagramIcon />
							
							</MUILink>
						</ListItem>
					</Tooltip>
					</List>
					<IconButton
						aria-label='open drawer'
						edge='end'
						onClick={handleDrawerToggle}
						sx={{ display: { md: 'none' }, color: '#fff' }}
					>
						<MenuIcon />
					</IconButton>
				</MainToolbar>
			</AppBarStyled>
			<DrawerNav aria-label='mailbox folders'>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant='temporary'
					anchor={'right'}
					open={mobileOpen}
					onClose={handleDrawerToggle}
					// classes={{
					// 	paper: classes.drawerPaper,
					// }}
					sx={{
						display: { md: 'none' },
						'& .MuiDrawer-paper': { width: drawerWidth },
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{drawer}
				</Drawer>
			</DrawerNav>
		</>
	);
}
