import * as React from 'react';
import { styled } from '@mui/system';
import { Link } from '@mui/material';
import MUILink from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { Stack, ListItem } from '@mui/material';

// @material-ui/icons
import Favorite from '@mui/icons-material/Favorite';

// import footerStyle from 'assets/jss/aleign/components/footerStyle.jsx';

const FooterWrap = styled(Box)({
	padding: '0.9375rem 0',
	textAlign: 'center',
	display: 'flex',
	zIndex: '2',
	position: 'relative',
	backgroundColor: 'transparent',
	// borderTop: '1px solid',
	// marginTop: '70px',
});

const FooterContainer = styled(Box)({
	paddingRight: '15px',
	paddingLeft: '15px',
	marginRight: 'auto',
	marginLeft: 'auto',
	width: '100%',
});

const Block = styled(Box)({
	padding: '0 0.9375rem',
	fontWeight: '500',
	fontSize: '12px',
	borderRadius: '3px',
	textDecoration: 'none',
	position: 'relative',
	display: 'block',
});

function Footer() {
	return (
		<FooterWrap>
			<FooterContainer>
				{/* <Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					spacing={4}
					className='list'
					sx={{ textTransform: 'uppercase', fontSize: '0.8rem', mb: 2 }}
				>
					<Link to='/' className='block'>
						Home
					</Link>
					<Link to='/services-page' className='block'>
						Services
					</Link>
					<Link to='/team-page' className='block'>
						The Team
					</Link>
					<Link to='/clinic-page' className='block'>
						Clinic Details
					</Link>
					<MUILink href='javascript:void(Tawk_API.toggle())' className='block'>
						Contact Us
					</MUILink>
					<MUILink href='#pablo' className='block'>
						Privacy Notice
					</MUILink>
				</Stack> */}
				<Block>
					<Typography variant='h5' className='title'>
						Bayswater Physiotherapy
					</Typography>
					<Typography variant='body1'>
						Suite 7, 488 Walter Rd East, Bayswater WA 6053 Perth, Western Australia.
						<span className='hilite'> Tel: (08) 9279 5666</span>
					</Typography>
				</Block>
				<Block>
					ABN 95 247 319 576 | Copyright &copy; Bayswater Physio{' '}
					{1900 + new Date().getYear()} | All Rights Reserved. &copy;{' '}
					{1900 + new Date().getYear()} | made with{' '}
					<Favorite
						sx={{ width: '18px', height: '18px', position: 'relative', top: '3px' }}
					/>{' '}
					for a brave NU web
				</Block>
			</FooterContainer>
		</FooterWrap>
	);
}

export default Footer;
