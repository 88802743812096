// Navbar dropped in from FullStackRocket... and updated jss-to-styled. muiV5

import React from 'react';
import { Link } from 'gatsby';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PhoneAndroid from '@mui/icons-material/PhoneAndroid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import useSiteMetadata from '../../src/components/hooks/SiteMetadata';

import MUILink from '@mui/material/Link';

import logo from '../img/BayswaterPhysioLogo500-white.png';

const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
// root: {
// 	display: 'flex',
// },
const DrawerNav = styled('nav')(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		width: drawerWidth,
		flexShrink: 0,
	},
}));
const AppBarStyled = styled(AppBar)(({ theme }) => ({
	color: theme.palette.primary.dark,
	// backgroundColor: 'white',
	[theme.breakpoints.up('sm')]: {
		width: `100%`,
	},
}));
const MainToolbar = styled(Toolbar)(({ theme }) => ({
	justifyContent: 'space-around',
	height: '90px',
	'& a': {
		color: '#fff',
		'&:hover': {
			textDecoration: 'none',
		},
	},
	[theme.breakpoints.down('sm')]: {
		justifyContent: 'space-between',
	},
}));
// title: {
// 	flexGrow: 1,
// },
// menuButton: {
// 	marginRight: theme.spacing(2),
// 	[theme.breakpoints.up('sm')]: {
// 		display: 'none',
// 	},
// },
// toolbar: theme.mixins.toolbar,
// drawerPaper: {
// 	width: drawerWidth, ???TS
// },
// content: {
// 	flexGrow: 1,
// 	padding: theme.spacing(3),
// },
// }));

const items = [
	{ path: '/about', label: 'About' },
	{ path: '/products', label: 'Products' },
	{ path: '/blog', label: 'Blog' },
	{ path: '/pcProds', label: 'PC Products' },
	{ path: '/contact', label: 'Contact' },
	// { path: '/contact/examples', label: 'Form Examples' },
];

const rightLinks = [
	{
		id: '1',
		path: '',
		label: 'tel: +61 (08) 9279 5666',
		SocialIcon: PhoneAndroid,
		toolTip: 'Get in Touch',
	},
	{
		id: '2',
		path: 'https://www.facebook.com/bayswaterphysiotherapy',
		label: '',
		SocialIcon: FacebookIcon,
		toolTip: 'Facebook',
	},
	{
		id: '3',
		path: 'https://www.instagram.com/bayswaterphysio/',
		label: '',
		SocialIcon: InstagramIcon,
		toolTip: 'Insta',
	},
];

export default function NavBarComponent(props) {
	const { container } = props;
	const { 
		title, 
		mainLogo, 
		description, 
		phone1, 
		facebook, 
		insta 
	} = useSiteMetadata();
	// const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	// TS NOTES: check muiv5 docs for any updates on the drawer component
	const drawer = (
		<div>
			<div sx={(theme) => theme.mixins.toolbar} />
			<List>
				{/* {items.map((item) => (
					<ListItem button key={item.path}>
						<MUILink component={Link} to={item.path}>
							<ListItemText primary={item.label} />
						</MUILink>
					</ListItem>
				))} */}
				{rightLinks.map(({ SocialIcon, ...link }) => (
					<Tooltip
						id={link.toolTip}
						title={link.toolTip}
						placement={
							typeof window !== 'undefined' && window.innerWidth > 959 ? 'top' : 'left'
						}
					>
						<ListItem button key={link.id}>
							{/* <Typography variant='h4'>{link.label}</Typography> */}
							<MUILink component={Link} to={link.path}>
								<SocialIcon />
								{link.label}
							</MUILink>
						</ListItem>
					</Tooltip>
				))}
			</List>
		</div>
	);

	return (
		<>
			<AppBarStyled position='fixed'>
				<MainToolbar>
					<MUILink
						component={Link}
						sx={{ margin: { xs: 'auto', md: '0 116px 0 0' } }}
						to='/'
					>
						<img // TS NOTES: can we use gatsby image here?
							src={mainLogo}
							alt='Bayswater Physiotherapy'
							style={{ height: '84px', padding: '10px 0 4px' }}
						/>
					</MUILink>
					{/* {items.map((item) => (
							<Button color='inherit' key={item.path}>
								<MUILink component={Link} to={item.path}>
									<ListItemText primary={item.label} />
								</MUILink>
							</Button>
						))} */}
					<List sx={{ display: { xs: 'none', md: 'flex' }, height: '100%', py: 0 }}>
						{rightLinks.map(({ SocialIcon, ...link }) => (
							<Tooltip
								id={link.toolTip}
								title={link.toolTip}
								placement={
									typeof window !== 'undefined' && window.innerWidth > 959
										? 'top'
										: 'left'
								}
							>
								<ListItem
									sx={{
										p: 0,
										color: '#fff',
										'&:hover': { backgroundColor: 'rgba(0,0,0,0.26)' },
									}}
									button
									key={link.id}
								>
									<Button
										sx={{ justifyContent: 'center', width: 'max-content', color: '#fff' }}
										href={link.path}
									>
										<SocialIcon />{' '}
										{link.label ? (
											<Typography sx={{ color: '#fff', mx: 3, my: 0 }} variant='h4'>
												{link.label}
											</Typography>
										) : null}
									</Button>
								</ListItem>
							</Tooltip>
						))}
					</List>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='end'
						onClick={handleDrawerToggle}
						sx={{ display: { md: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
				</MainToolbar>
			</AppBarStyled>
			<DrawerNav aria-label='mailbox folders'>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant='temporary'
					anchor={'right'}
					open={mobileOpen}
					onClose={handleDrawerToggle}
					// classes={{
					// 	paper: classes.drawerPaper,
					// }}
					sx={{
						display: { md: 'none' },
						'& .MuiDrawer-paper': { width: drawerWidth },
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{drawer}
				</Drawer>
			</DrawerNav>
		</>
	);
}
