import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';

import Container from '@mui/material/Container';

import FooterMui from '../components/Footer-mui';
import Navbar from '../components/Navbar';
import NavbarLogoSocial from './NavbarLogoSocial';
import SectionCarousel from '../components/SectionCarousel';
import SlickSliderTest from '../components/SlickSliderTest';

// import "./all.sass"; TS NOTE: replace sass with MUI theme
// import useSiteMetadata from "../../plugins/gatsby-plugin-top-layout/SiteMetadata"; TS NOTE: move Helmet to TopLayout to implement MUI theme
// import { withPrefix } from "gatsby";

import BPLogo from '../img/logo_watermark.png';

const TemplateWrapper = ({ children }) => {
	// const { title, description } = useSiteMetadata();
	return (
		<>
			<ParallaxProvider>
				<NavbarLogoSocial />
				<Container
					sx={{
						p: { xs: '0 0 120px' },
						top: 90,
						display: 'flex',
						minHeight: '100vh',
						flexDirection: 'column',
						position: 'relative',
						overflow: 'hidden'
					}}
					maxWidth='100%'
				>
					{children}
				</Container>
				<FooterMui />
			</ParallaxProvider>
		</>
	);
};

export default TemplateWrapper;
