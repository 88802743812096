import React from 'react';
import { styled } from '@mui/system';
import { useParallax } from 'react-scroll-parallax';

// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
// import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import LocationOn from '@mui/icons-material/LocationOn';
// core components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
// import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx"
import image1 from '../img/BayswaterCarousel_0000.jpg';
import image2 from '../img/BayswaterCarousel_0001.jpg';
import image3 from '../img/BayswaterCarousel_0002.jpg';
import image4 from '../img/BayswaterCarousel_0003.jpg';
import image5 from '../img/BayswaterCarousel_0004.jpg';
import image6 from '../img/BayswaterCarousel_0005.jpg';
import image7 from '../img/BayswaterCarousel_0006.jpg';
import image8 from '../img/BayswaterCarousel_0007.jpg';

const SlickSection = styled('div')({
	padding: '0',
	position: 'absolute',
	width: '100%',
	zIndex: -1,
	top: 0,
});
const SlickContainer = styled('div')({
	padding: '0',
	// marginTop: '30px',
});
// marginAuto: {
//   marginLeft: "auto !important",
//   marginRight: "auto !important",
// },
const SlickCard = styled(Card)({
	border: '0',
	// marginBottom: '30px',
	// marginTop: '30px',
	borderRadius: '0',
	color: 'rgba(0, 0, 0, 0.87)',
	background: 'transparent',
	width: '100%',
	boxShadow: 'none',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	minWidth: '0',
	wordWrap: 'break-word',
	fontSize: '.875rem',
	transition: 'all 300ms linear',
	margin: '0',
	overflow: 'hidden',

	'& .slick-track': {
		display: 'flex !important',
	},
	'& .slick-slide': {
		height: 'inherit !important;',
		'& img': {
			objectFit: 'cover',
			height: 'auto',
			width: '100%',
		},
	},
});

const SectionCarousel = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		fade: true,
	};
	// const sliderMove = useParallax({ translateY: [-260, 300] });

	return (
		<SlickSection >
			<SlickContainer>
				<Grid container>
					<Grid
						item
						xs={12}
						sm={12}
						sx={{ marginLeft: 'auto !important', marginRight: 'auto !important' }}
					>
						<SlickCard>
							<Carousel {...settings}>
								<div>
									<img src={image1} alt='First slide' className='slick-image' />
									{/* <div className='slick-caption'>
											<h4>
												<LocationOn className='slick-icons' />
												some kind of caption
											</h4>
										</div> */}
								</div>
								<div>
									<img src={image2} alt='Second slide' className='slick-image' />
									{/* <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div> */}
								</div>
								<div>
									<img src={image3} alt='Third slide' className='slick-image' />
									{/* <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div> */}
								</div>
								<div>
									<img src={image4} alt='4 slide' className='slick-image' />
									{/* <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div> */}
								</div>
								<div>
									<img src={image5} alt='5 slide' className='slick-image' />
									{/* <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div> */}
								</div>
								<div>
									<img src={image6} alt='6 slide' className='slick-image' />
									{/* <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div> */}
								</div>
								<div>
									<img src={image7} alt='7 slide' className='slick-image' />
									{/* <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div> */}
								</div>
								<div>
									<img src={image8} alt='8 slide' className='slick-image' />
									{/* <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div> */}
								</div>
							</Carousel>
						</SlickCard>
					</Grid>
				</Grid>
			</SlickContainer>
		</SlickSection>
	);
};

export default SectionCarousel;
